<template>
  <v-app>
    <v-app-bar flat color="black" app>
      <div class="d-flex align-center" @click="$router.push({name:'Home'})" style="cursor:pointer">
        <v-img alt="Purtrak Logo" class="mr-2" contain :src="require('./assets/logo.png')" width="20"/>
        <div class="ml-4 text-h5 white--text">PURTRAK</div>
      </div>

      <v-spacer></v-spacer>

      <template v-if="isMobile">
        <v-menu left nudge-bottom="50">
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" x-large color="white"><v-icon>mdi-menu</v-icon></v-btn>
          </template>
          <v-list nav class="text-h5" width="300">
            <v-list-item :to="{name:'Why'}">Why Purtrak?</v-list-item>
            <v-list-item :to="{name:'Features'}">Features</v-list-item>
            <v-list-item :to="{name:'Pricing'}">Pricing</v-list-item>
            <v-divider/>
            <v-list-item href="https://app7.purtrak.com">SIGN IN</v-list-item>
            <v-list-item href="https://app7.purtrak.com/signup">Start free trial</v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-else>
        <v-btn plain color="white" :to="{name:'Why'}">Why Purtrak?</v-btn>
        <v-btn plain color="white" :to="{name:'Features'}">Features</v-btn>
        <v-btn plain color="white" :to="{name:'Pricing'}">Pricing</v-btn>
        <v-btn outlined color="white" class="mr-4" href="https://app7.purtrak.com">Sign In</v-btn>
        <v-btn color="white"  depressed href="https://app7.purtrak.com/signup">Start free trial</v-btn>
      </template>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <div class="black white--text pa-10">
      &copy; Copyright 2022 Purtrak Inc.
      <div class="float-right">
<!--
        <v-btn text small color="white" :to="{name:'About'}">About us</v-btn>
-->
        <v-btn text small color="white" :to="{name:'Contact'}">Contact</v-btn>
        <v-btn text small color="white" :to="{name:'Terms'}">Terms</v-btn>
        <v-btn text small color="white" :to="{name:'Privacy'}">Privacy</v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },

};
</script>
