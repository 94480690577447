import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'Home', component: () => import('../views/Home.vue')},
  {path: '/why', name: 'Why', component: () => import('../views/Why.vue')},
  {path: '/features', name: 'Features',  component: () => import('../views/Features.vue'),
    redirect: () => ({name:'Production'}),
    children: [
      {path: 'production', name: 'Production', component: () => import('../views/Production.vue')},
      {path: 'inventory', name: 'Inventory', component: () => import('../views/Inventory.vue')},
      {path: 'sales', name: 'Sales', component: () => import('../views/Sales.vue')},
      {path: 'reports', name: 'Reports', component: () => import('../views/Reports.vue')},
      {path: 'planning', name: 'Planning', component: () => import('../views/Planning.vue')},
      {path: 'apps', name: 'Apps', component: () => import('../views/Apps.vue')}
    ]
  },
  {path: '/pricing', name: 'Pricing', component: () => import('../views/Pricing.vue')},
  {path: '/contact', name: 'Contact', component: () => import('../views/Contact.vue')},
  {path: '/demo', name: 'Demo', component: () => import('../views/Demo.vue')},
  {path: '/about', name: 'About', component: () => import('../views/About.vue')},
  {path: '/terms', name: 'Terms', component: () => import('../views/Terms.vue')},
  {path: '/privacy', name: 'Privacy', component: () => import('../views/Privacy.vue')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { y: 0 } // always scroll to top
  }
})

router.afterEach((to) => {
  gtag('config', 'UA-180773148-1', {
    page_path: to.fullPath,
    send_page_view: true,
  });
});

export default router
